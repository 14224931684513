import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Countdown from 'react-countdown'
import { ClassicSpinner } from 'react-spinners-kit'
import { useWeb3React } from '@web3-react/core'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import Slider from './slider'
import MINTCONTRACT_ABI from '../../assets/abis/MINTCONTRACT_ABI.json'
import POODLETOKEN_ABI from '../../assets/abis/POODLETOKEN_ABI.json'
import config, { BurnPoodle } from '../../config/config'

const ethers = require('ethers')

const Mint = () => {
  const [publicState, setPublicState] = useState(false)
  const { account } = useWeb3React()
  const [mintPrice, setMintPrice] = useState(0)
  const [whiteListCount, setWhiteListCount] = useState(0)
  const [totalMintedCount, setTotalMintedCount] = useState(0)
  const [mintCount, setMintCount] = useState(1)
  const [mintState, setMintState] = useState(false)

  const Provider = new ethers.providers.Web3Provider(window.ethereum)
  const Signer = Provider.getSigner()

  const NFTMintContract = new ethers.Contract(
    config.MINTCONTRACT_ADDRESS,
    MINTCONTRACT_ABI,
    Signer,
  )

  const POODLETOKENContract = new ethers.Contract(
    config.POODLETOKEN_ADDRESS,
    POODLETOKEN_ABI,
    Signer,
  )

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <h1 className="text-2xl">0D : 0H : 0M : 0S</h1>
    } else {
      // Render a countdown
      return (
        <h1 className="text-2xl">
          {days}D : {hours}H : {minutes}M : {seconds}S
        </h1>
      )
    }
  }

  const controlMintCount = (state) => {
    if (state === true) {
      mintCount >= 1 ? setMintCount(1) : setMintCount(mintCount + 1)
    } else {
      mintCount <= 1 ? setMintCount(1) : setMintCount(mintCount - 1)
    }
  }

  const countDownComplete = () => {
    setPublicState(true)
    setMintPrice(0)
  }

  const mintFunc = async () => {
    if (account) {
      setMintState(true)
      if (publicState) {
        try {
          const tx = await POODLETOKENContract.approve(
            config.MINTCONTRACT_ADDRESS,
            ethers.utils.parseEther(config.BurnPoodle.toString()),
            {
              gasLimit: 300000,
            },
          )
          await tx.wait()
          const mintTx = await NFTMintContract.mintWithPOODLE(mintCount, {
            gasLimit: config.totalGas * mintCount,
            value: ethers.utils.parseEther((mintPrice * mintCount).toString()),
          })
          await mintTx.wait()
          NotificationManager.success('Minted successfully!')
          setMintState(false)
          isWhiteList()
          totalSupply()
        } catch (error) {
          setMintState(false)
        }
      } else {
        try {
          const tx = await POODLETOKENContract.approve(
            config.MINTCONTRACT_ADDRESS,
            ethers.utils.parseEther(config.BurnPoodle.toString()),
            {
              gasLimit: 300000,
            },
          )
          await tx.wait()
          const mintTx = await NFTMintContract.mintWithPOODLE(mintCount, {
            gasLimit: config.totalGas * mintCount,
            value: ethers.utils.parseEther((mintPrice * mintCount).toString()),
          })
          await mintTx.wait()
          NotificationManager.success('Minted successfully!')
          setMintState(false)
          isWhiteList()
          totalSupply()
        } catch (error) {
          setMintState(false)
        }
      }
    } else {
      NotificationManager.info('Please Connect wallet')
    }
  }

  const totalSupply = async () => {
    let balance = 0
    if (account !== undefined) {
      balance = await NFTMintContract.totalSupply()
      const count = Number(balance.toString())
      setTotalMintedCount(count)
      if (count >= config.MaxMintCount) {
      }
    }
  }

  const isWhiteList = async () => {
    if (account) {
      try {
        let mintCountBigNumber = await NFTMintContract.getMintCount(account)
        let mintCount = mintCountBigNumber.toNumber() // Convert BigNumber to a standard number
        console.log(`Mint count for account ${account}:`, mintCount)
        setWhiteListCount(mintCount)
      } catch (error) {
        console.error('Error fetching mint count:', error)
      }
    } else {
      console.log('No account found.')
    }
  }

  useEffect(() => {
    if (account) {
      totalSupply()
      isWhiteList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
    >
      <div className="container relative small">
        <h3
          className="fn__maintitle font-extrabold mt-32 my-10 text-3xl text-center"
          data-align="left"
        ></h3>
        <div className="nft_single_img">
          <div className="item">
            <div className="left__border"></div>
            <div className="right__border"></div>
            <div className="item_in">
              <Slider />
            </div>
          </div>
        </div>
        <div className="desc"></div>
        <div className="metaverse_fn_mintbox">
          <div className="mint_title">
            <span>{!publicState ? 'Whitelist' : 'Public'} Mint is Live</span>
          </div>
          <div className="mint_end">
            <div className="flex text-2xl">
              <h1>END IN : </h1>
              <Countdown
                date={Date.UTC(2024, 2, 8, 17, 0, 0)} // March 8th, 2024, 17:00 UTC
                //date={Date.UTC(2023, 6, 16, 16, 0, 0)} // months are 0-indexed
                intervalDelay={0}
                precision={3}
                onComplete={() => countDownComplete()}
                renderer={renderer}
              />
            </div>
          </div>
          <div className="mint_body">
            <div className="mint_content">
              <div className="mint_list">
                <ul>
                  <li>
                    <div className="item">
                      <h4>WL Price</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          {' '}
                          1B $POODLE{' '}
                        </span>
                      </h3>
                    </div>
                  </li>
                  {publicState && (
                    <li>
                      <div className="item">
                        <h4>Quantity</h4>
                        <div className="flex gap-3 justify-center text-2xl w-full">
                          <span
                            className="cursor-pointer decrease"
                            onClick={() => controlMintCount(false)}
                          >
                            -
                          </span>
                          <span className="summ" data-price="2.25">
                            {mintCount}
                          </span>
                          <span
                            className="cursor-pointer increase"
                            onClick={() => controlMintCount(true)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="item">
                      <h4>Minted</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          {totalMintedCount}
                        </span>{' '}
                        <span className="total_price font-bold text-xl text-white">
                          / 750
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <h4>Total Price</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          1B POODLE + GAS{' '}
                        </span>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
              {totalMintedCount !== config.MaxMintCounts && (
                <div className="mint_desc">
                  <button
                    className={`metaverse_fn_button ${
                      whiteListCount === 2 ? 'disabled' : ''
                    }`}
                    onClick={() => mintFunc()}
                    disabled={whiteListCount === 2}
                  >
                    <span className="text">Mint Now </span>
                  </button>
                </div>
              )}
              {whiteListCount === 2 && (
                <div className="mint_desc">
                  <h1 className="font-bold text-xl text-white">
                    Leave some for others :)
                  </h1>
                </div>
              )}
              {totalMintedCount === config.MaxMintCounts && (
                <div className="mint_desc">
                  <h1 className="font-bold ml-5 mt-2 text-md text-white">
                    Thank you! WE ARE SOLD OUT
                  </h1>
                </div>
              )}{' '}
            </div>

            <div className="mint_checked">{publicState && <p></p>}</div>
          </div>
        </div>
        {mintState && (
          <div className="metaverse_fn_preloader opacity-90 rounded-2xl">
            <ClassicSpinner size={40} />
            <h1 className="font-bold ml-5 mt-2 text-md text-white">
              {' '}
              Minting PoodleFriends Island...
            </h1>
          </div>
        )}
      </div>
      <NotificationContainer />
    </motion.section>
  )
}

export default Mint
