import Mint from '../mint'

const Main = () => {
  return (
    <>
      <Mint />
    </>
  )
}

export default Main
